import React from 'react'
import { graphql } from 'gatsby'
import { Accordion, Card, Button } from 'react-bootstrap'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'
import WSCLogo from '../images/wsclogo.jpg'
import Webinar1 from '../images/webinar-1.png'
import FamilyGross from '../images/familyongrasscalculator.png'
import FamilyLying from '../images/family2.png'
import CoupleConsulting from '../images/consulting.png'
import DavidShaw from '../images/david-shaw-headshot.png'
import JasonAtkins from '../images/jason-atkins-headshot.png'

class UpcomingEvents extends React.Component {
	render() {
		const siteTitle = 'Upcoming Events'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 id="checklist-header">Upcoming Events</h3>
								<Accordion defaultActiveKey="0">
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="1st"
										>
											<i className="fa fa-calendar" /> Join Us For How Can You
											Fund Your Children's Schooling
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1st">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={WSCLogo}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																What Should Investors Make of Cryptocurrency
																Mania?
															</strong>
														</p>
														<p>
															This webinar occurs several times. Please register
															for the date and time that works best for you.
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://register.gotowebinar.com/webinarOver"
																	alt="Children's Schooling"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															Cryptocurrencies, like Bitcoin, are independent
															and not regulated by any central authority. Until
															recently, these digital currencies were not
															treated in the same way as cash for tax purposes
															in Australia.
														</p>
														<p>
															Join WSC Group's National Financial Planning
															Manager, Jason Atkins and National Superannuation
															Director, Lindsay Davis as the delve into the most
															common investment and tax questions surrounding
															Cyrptocurrency.
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>
															<a
																href="https://link.gotowebinar.com/help-system-requirements-attendees"
																alt="Register"
																style={{
																	color: `#309DDC`,
																	textDecoration: `none`,
																}}
															>
																View System Requirements
															</a>
														</p>
														<p>
															<img
																className="events-img"
																src="https://d33wubrfki0l68.cloudfront.net/img/wsc/2f4e5322b9e154dca1123f57c707a407c51cefb0/school.jpeg"
																alt="Family"
															/>
														</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2nd"
										>
											<i className="fa fa-calendar" /> Join Us For What Should
											Investors Make Of Cryptocurrency Mania
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2nd">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={WSCLogo}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																What Should Investors Make of Cryptocurrency
																Mania?
															</strong>
														</p>
														<p>
															This webinar occurs several times. Please register
															for the date and time that works best for you.
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://register.gotowebinar.com/webinarOver"
																	alt="Cryptocurrency"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															Cryptocurrencies, like Bitcoin, are independent
															and not regulated by any central authority. Until
															recently, these digital currencies were not
															treated in the same way as cash for tax purposes
															in Australia.
														</p>
														<p>
															Join WSC Group's National Financial Planning
															Manager, Jason Atkins and National Superannuation
															Director, Lindsay Davis as the delve into the most
															common investment and tax questions surrounding
															Cyrptocurrency.
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>
															<a
																href="https://link.gotowebinar.com/help-system-requirements-attendees"
																alt="Register"
																style={{
																	color: `#309DDC`,
																	textDecoration: `none`,
																}}
															>
																View System Requirements
															</a>
														</p>
														<p>
															<img
																className="events-img"
																src="https://d33wubrfki0l68.cloudfront.net/img/wsc/9fca4b787e5232aae9e935ff350820b003128936/crypto.jpeg"
																alt="crypto"
															/>
														</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="3rd"
										>
											<i className="fa fa-calendar" /> Join Us For New Data
											Breach Laws Are Now In Effect
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3rd">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={WSCLogo}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																New Data Breach Laws are Now in Effect
															</strong>
														</p>
														<p>
															This webinar occurs several times. Please register
															for the date and time that works best for you.
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://register.gotowebinar.com/webinarOver"
																	alt="Data Breach Laws"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															New data breach rules in effect from 22 February
															2018 place an onus on business to protect and
															notify individuals whose personal information is
															involved in a data breach that is likely to result
															in serious harm.
														</p>
														<p>
															Regardless of how good your existing systems are,
															data breaches are a reality either through human
															error, mischief, or simply because those looking
															for ways to disrupt are often one step ahead. But
															it’s not all about IT, there have been numerous
															cases of hard copy records being disposed of
															inappropriately, employees allowing viruses to
															penetrate servers after opening the wrong email,
															and sensitive data on USBs lost on the way home.
														</p>
														<p>
															Who is covered by the data breach scheme? What do
															you need to do? How can you protect yourself and
															your business?
														</p>
														<p>
															Join WSC Group and Shadforth Insurance Brokers as
															they provide an overview of the new regulations
															and what you can do to protect yourself and your
															business.
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>
															<a
																href="https://support.goto.com/webinar/help/system-requirements-for-attendees-g2w010003?c_prod=g2w&c_name=iph"
																alt="Register"
																style={{
																	color: `#309DDC`,
																	textDecoration: `none`,
																}}
															>
																View System Requirements
															</a>
														</p>
														<p>
															<img
																className="events-img"
																src="https://d33wubrfki0l68.cloudfront.net/img/wsc/5708c9028922d3cf19d8cf9cb158b4c3861523a5/data.jpeg"
																alt="on computer"
															/>
														</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="4th"
										>
											<i className="fa fa-calendar" /> WEBINAR 1
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="4th">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={Webinar1}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																Please register for Single Touch Payroll (STP) -
																General Information on 30 April, 2019 12:00 PM
																AEST at:
															</strong>
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://attendee.gotowebinar.com/register/2678662511474041355"
																	alt="Webinar 1"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>Brought to you by GoToWebinar®</p>
														<p>Webinars Made Easy®</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="5th"
										>
											<i className="fa fa-calendar" /> WEBINAR 2
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="5th">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={Webinar1}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																Please register for Single Touch Payroll (STP) -
																MYOB on May 1, 2019 12:00 PM AEST at:
															</strong>
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://register.gotowebinar.com/webinarOver"
																	alt="Webinar 2"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>Brought to you by GoToWebinar®</p>
														<p>Webinars Made Easy®</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="6th"
										>
											<i className="fa fa-calendar" /> WEBINAR 3
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="6th">
											<Card.Body className="events-body">
												<div className="events-wrapper">
													<div style={{ textAlign: `center` }}>
														<img
															src={Webinar1}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<hr />
													<div>
														<p>
															<strong>
																Please register for Single Touch Payroll (STP) -
																Xero on May 2, 2019 12:00 PM AEST at:
															</strong>
														</p>
														<p style={{ textAlign: `center` }}>
															<Button
																className="events-button"
																variant="primary"
															>
																<a
																	href="https://attendee.gotowebinar.com/register/2316853916703244811"
																	alt="Webinar 3"
																>
																	Register Now!
																</a>
															</Button>
														</p>
														<p>
															After registering, you will receive a confirmation
															email containing information about joining the
															webinar.
														</p>
														<p>Brought to you by GoToWebinar®</p>
														<p>Webinars Made Easy®</p>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="7th"
										>
											<i className="fa fa-calendar" /> NEWCASTLE
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="7th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v2">
													<div style={{ backgroundColor: `#b7bdc4` }}>
														<p className="newcastle-head">
															An Evening with David Shaw
														</p>
													</div>
													<div style={{ textAlign: `center` }}>
														<img
															src={FamilyGross}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<div className="events-wrapper-v2-inner">
														<div>
															<p className="events-head-v2">
																<strong>The 10 Years Before Retirement</strong>
															</p>
															<p className="events-subhead-v2">
																<strong>
																	<em>
																		You are invited to a FREE information
																		evening on what you need to know and what
																		you need to do NOW to be ready for
																		retirement.
																	</em>
																</strong>
															</p>
															<ul className="list-none">
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Have you got a plan to earn $70,000 to
																	$100,000 per year in retirement?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Do you understand the income and asset tests
																	and the effect that investment properties have
																	from a Centrelink point of view?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Do you understand what income you can earn,
																	tax free, both inside and outside of
																	superannuation?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Have you got a transition to retirement
																	strategy in place?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Why is it good to have a split of assets
																	between superfund and non superfund assets?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	What is your plan for exiting debt in
																	retirement?
																</li>
																<li>
																	<i
																		aria-hidden="true"
																		class="fa fa-dot-circle-o"
																	>
																		&nbsp;
																	</i>
																	Do you have a plan to sell or transfer your
																	business to children or employees?
																</li>
															</ul>
															<p style={{ textAlign: `center` }}>
																<Button
																	className="events-button"
																	variant="primary"
																>
																	<a
																		href="https://www.getfeedback.com/r/6NBCwmzB"
																		alt="Newcastle"
																	>
																		Register Now
																	</a>
																</Button>
															</p>
															<div className="row mt-4">
																<div className="col">
																	<p>
																		<strong>DATE:</strong>
																		<br /> Wednesday, 23 August 2017
																	</p>
																	<p>
																		<strong>TIME:</strong>
																		<br /> 6:15pm registration for a 6:30pm
																		start Light refreshments will be available
																		upon arrival
																	</p>
																	<p>
																		<strong>VENUE: </strong>
																		<br /> Crowne Plaza Newcastle Cnr
																		Mereweather Street & Wharf Road
																	</p>
																	<p>
																		<strong>COST:</strong>
																		<br /> FREE, but bookings are essential
																	</p>
																	<p>
																		<strong>RSVP:</strong>
																		<br />
																		At{' '}
																		<a href="mailto:info@wscgroup.com.au">
																			info@wscgroup.com.au
																		</a>{' '}
																		or 1300 365 125
																	</p>
																</div>
																<div className="col">
																	<div className="my-5">
																		<img
																			src={FamilyLying}
																			alt="Family Lying Down"
																		/>
																	</div>
																	<div className="my-5">
																		<img
																			src={CoupleConsulting}
																			alt="Couple Consulting"
																		/>
																	</div>
																</div>
															</div>
															<hr style={{ borderTop: `2px solid #b7bdc4` }} />
															<div className="row">
																<div className="col">
																	<img src={DavidShaw} alt="David Shaw" />
																</div>
																<div className="col">
																	<p>
																		<strong>David Shaw,</strong> CEO WSC Group
																	</p>
																	<p>
																		David Shaw is a property tax expert and has
																		spoken at property tax seminars throughout
																		the country over the last 12 years. David
																		also has many years' experience with
																		extensive knowledge in commerce, industry
																		and public practice accounting.
																	</p>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<img src={JasonAtkins} alt="Jason Atkins" />
																</div>
																<div className="col">
																	<p>
																		<strong>Jason Atkins,</strong> Manager WSC
																		Group Wealth Management
																	</p>
																	<p>
																		Jason has over 16-years' experience as a
																		financial planner, providing outcomes to
																		help his client achieve their financial and
																		life goals. He has provided advice at many
																		levels, ranging from basic advice to
																		creating complex ongoing plans for Private
																		Clients and Business Owners with significant
																		wealth and income.
																	</p>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<div
																		className="mb-4"
																		style={{ textAlign: `center` }}
																	>
																		<img
																			src={WSCLogo}
																			alt="WSC"
																			style={{ marginBottom: `0` }}
																		/>
																	</div>
																	<small>
																		WSC Group- Aust Wealth Management is an
																		Authorised Representative (No. 001232852 and
																		Credit Representative No. 478100) of FYG
																		Planners Pty Ltd, AFSL/ACL (No. 224543)
																	</small>
																	<p
																		className="mt-4"
																		style={{ textAlign: `center` }}
																	>
																		<Button
																			className="events-button"
																			variant="primary"
																		>
																			<a
																				href="https://www.getfeedback.com/r/6NBCwmzB/q/closed"
																				alt="Get Feedback"
																			>
																				<i
																					aria-hidden="true"
																					className="fa fa-cog"
																				>
																					&nbsp;
																				</i>
																				Register Now!
																			</a>
																		</Button>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="8th"
										>
											<i className="fa fa-calendar" /> SYDNEY
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="8th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v3">
													<div className="container">
														<div className="row">
															<div className="col">
																<h5>No events for now.</h5>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="9th"
										>
											<i className="fa fa-calendar" /> CANBERRA
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="9th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v3">
													<div className="container">
														<div className="row">
															<div className="col">
																<h5>No events for now.</h5>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="10th"
										>
											<i className="fa fa-calendar" /> BRISBANE
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="10th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v3">
													<div className="container">
														<div className="row">
															<div className="col">
																<h5>No events for now.</h5>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="11th"
										>
											<i className="fa fa-calendar" /> GOLD COAST
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="11th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v3">
													<div className="container">
														<div className="row">
															<div className="col">
																<h5>No events for now.</h5>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="12th"
										>
											<i className="fa fa-calendar" /> MELBOURNE
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="12th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v3">
													<div className="container">
														<div className="row">
															<div className="col">
																<h5>No events for now.</h5>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="13th"
										>
											<i className="fa fa-calendar" /> PERTH
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="13th">
											<Card.Body className="events-body">
												<div className="events-wrapper-v2">
													<div style={{ backgroundColor: `#b7bdc4` }}>
														<p className="newcastle-head">
															An Evening with David Shaw
														</p>
													</div>
													<div style={{ textAlign: `center` }}>
														<img
															src={FamilyGross}
															alt="WSC"
															style={{ marginBottom: `0` }}
														/>
													</div>
													<div className="events-wrapper-v2-inner">
														<div>
															<p className="events-head-v2">
																<strong>
																	An Evening with David Shaw - <br />
																	What's New in Property Tax?
																</strong>
															</p>
															<p className="events-subhead-v2">
																<strong>
																	<em>
																		Do you have a plan in place to reach your
																		property and wealth goals?
																	</em>
																</strong>
															</p>
															<p>
																There has been much speculation in the media
																recently about the future of property
																investment. As a property investor, what do you
																need to know in order to achieve your property
																goals? Learn how other property investors have
																achieved their goals.
															</p>
															<p>
																Join WSC Group Founder and CEO, David Shaw, for
																an information evening to hear the latest tax
																updates for property investors.
															</p>
															<p style={{ textAlign: `center` }}>
																<Button
																	className="events-button"
																	variant="primary"
																>
																	<a
																		href="wscgroup.createsend1.com/t/d-l-khiulyk-l-r/"
																		alt="Perth"
																	>
																		Register Now
																	</a>
																</Button>
															</p>
															<div className="row mt-4">
																<div className="col">
																	<p>
																		<strong>DATE:</strong>
																		<br /> Thursday, 10 August 2017
																	</p>
																	<p>
																		<strong>TIME:</strong>
																		<br /> 6:15pm registration for a 6:30pm
																		start Light refreshments will be available
																		upon arrival
																	</p>
																	<p>
																		<strong>VENUE: </strong>
																		<br /> Metro Hotel Perth 61 Canning Highway,
																		South Perth
																	</p>
																	<p>
																		<strong>COST:</strong>
																		<br /> FREE, but bookings are essential
																	</p>
																	<p>
																		<strong>RSVP:</strong>
																		<br />
																		At{' '}
																		<a href="mailto:info@wscgroup.com.au">
																			info@wscgroup.com.au
																		</a>{' '}
																		or 1300 365 125
																	</p>
																</div>
																<div className="col text-center mt-5">
																	<p>
																		<strong>
																			How can you maximise your property
																			deductions?
																		</strong>
																	</p>
																	<p>
																		<strong>
																			Why is the correct ownership percentage of
																			property important?
																		</strong>
																	</p>
																	<p>
																		<strong>
																			How to get your loan structures correct to
																			maximise tax deductions.
																		</strong>
																	</p>
																	<p>
																		<strong>
																			What do you need to think about in the
																			10-years before retirement?
																		</strong>
																	</p>
																</div>
															</div>
															<hr style={{ borderTop: `2px solid #b7bdc4` }} />
															<div className="row">
																<div className="col">
																	<img src={DavidShaw} alt="David Shaw" />
																</div>
																<div className="col">
																	<p>
																		<strong>David Shaw,</strong> CEO WSC Group
																	</p>
																	<p>
																		David Shaw is a property tax expert and has
																		spoken at property tax seminars throughout
																		the country over the last 12 years. David
																		also has many years' experience with
																		extensive knowledge in commerce, industry
																		and public practice accounting.
																	</p>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<img src={JasonAtkins} alt="Jason Atkins" />
																</div>
																<div className="col">
																	<p>
																		<strong>Jason Atkins,</strong> Manager WSC
																		Group Wealth Management
																	</p>
																	<p>
																		Jason has over 16-years' experience as a
																		financial planner, providing outcomes to
																		help his client achieve their financial and
																		life goals. He has provided advice at many
																		levels, ranging from basic advice to
																		creating complex ongoing plans for Private
																		Clients and Business Owners with significant
																		wealth and income.
																	</p>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<div
																		className="mb-4"
																		style={{ textAlign: `center` }}
																	>
																		<img
																			src={WSCLogo}
																			alt="WSC"
																			style={{ marginBottom: `0` }}
																		/>
																	</div>
																	<small>
																		WSC Group- Aust Wealth Management is an
																		Authorised Representative (No. 001232852 and
																		Credit Representative No. 478100) of FYG
																		Planners Pty Ltd, AFSL/ACL (No. 224543)
																	</small>
																	<p
																		className="mt-4"
																		style={{ textAlign: `center` }}
																	>
																		<Button
																			className="events-button"
																			variant="primary"
																		>
																			<a
																				href="https://www.getfeedback.com/r/6NBCwmzB/q/closed"
																				alt="Get Feedback"
																			>
																				<i
																					aria-hidden="true"
																					className="fa fa-cog"
																				>
																					&nbsp;
																				</i>
																				Register Now!
																			</a>
																		</Button>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default UpcomingEvents

export const UpcomingEventsPageQuery = graphql`
	query UpcomingEventsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
